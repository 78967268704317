import _ from 'lodash';
import React from 'react';

import { PersonInfo, /* SortOptions, */ useAppState } from 'src/Context';

// type Params = { pageSize: number };
export const useFiltered = () => {
  const {
    hostages: hostagesState,
    returnedHostages: returnedHostagesState,
    earlierKidnepped: earlierKidneppedState,
    returnedIn2025: returnedIn2025State,
    returnedByTsahal: returnedByTsahalState,
    filters,
    sorting,
  } = useAppState();

  const { hostages, returnedHostages, earlierKidnepped, returnedIn2025, returnedByTsahal } = React.useMemo<{
    hostages: any[];
    returnedHostages: any[];
    earlierKidnepped: any[];
    returnedIn2025: any[];
    returnedByTsahal: any[];
  }>(() => {
    const filteredHostages: any = _.map(hostagesState, (group) => {
      return group.filter((item: PersonInfo) => {
        const fullName = item.b && item.c ? `${item.b} ${item.c}` : undefined;
        const isSearchPassed =
          fullName && filters.search
            ? filters.search.split(' ').every((word) => fullName.toLowerCase().includes(word))
            : true;

        return isSearchPassed;
      });
    });

    const filteresReturnedHostages: any = _.map(returnedHostagesState, (group) => {
      return group.filter((item: PersonInfo) => {
        const fullName = item.b && item.c ? `${item.b} ${item.c}` : undefined;
        const isSearchPassed =
          fullName && filters.search
            ? filters.search.split(' ').every((word) => fullName.toLowerCase().includes(word))
            : true;

        return isSearchPassed;
      });
    });

    const filteresEarlierKidnepped: any = earlierKidneppedState.filter((item: PersonInfo) => {
      const fullName = item.b && item.c ? `${item.b} ${item.c}` : undefined;
      const isSearchPassed =
        fullName && filters.search
          ? filters.search.split(' ').every((word) => fullName.toLowerCase().includes(word))
          : true;

      return isSearchPassed;
    });

    const filteresReturnedIn2025: any = _.map(returnedIn2025State, (group) => {
      return group.filter((item: PersonInfo) => {
        const fullName = item.b && item.c ? `${item.b} ${item.c}` : undefined;
        const isSearchPassed =
          fullName && filters.search
            ? filters.search.split(' ').every((word) => fullName.toLowerCase().includes(word))
            : true;

        return isSearchPassed;
      });
    });

    const filteresReturnedByTsahal: any = _.map(returnedByTsahalState, (group) => {
      return group.filter((item: PersonInfo) => {
        const fullName = item.b && item.c ? `${item.b} ${item.c}` : undefined;
        const isSearchPassed =
          fullName && filters.search
            ? filters.search.split(' ').every((word) => fullName.toLowerCase().includes(word))
            : true;

        return isSearchPassed;
      });
    });

    return {
      hostages: _.filter(filteredHostages, (g) => {
        return g.length;
      }),
      returnedHostages: _.filter(filteresReturnedHostages, (g) => {
        return g.length;
      }),
      earlierKidnepped: filteresEarlierKidnepped,
      returnedIn2025: _.filter(filteresReturnedIn2025, (g) => {
        return g.length;
      }),
      returnedByTsahal: _.filter(filteresReturnedByTsahal, (g) => {
        return g.length;
      }),
    };
  }, [
    hostagesState,
    filters,
    sorting,
    returnedHostagesState,
    earlierKidneppedState,
    returnedIn2025State,
    returnedByTsahalState,
  ]);

  return { hostages, returnedHostages, earlierKidnepped, returnedIn2025, returnedByTsahal };
};
