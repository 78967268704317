import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { useAppState, useAppDispatch, PersonInfo } from 'src/Context';
import MakoAnalytics from 'src/components/common/MakoAnalytics';
import PersonModal from 'src/components/common/PersonModal';
import { useResponsive } from 'src/hooks/responsive';
import { useFiltered } from 'src/hooks/use-filtered';
import images from 'src/static/assets';
import { analytics } from 'src/utils';
import { EARLIER_KIDNEPPED } from 'src/constants';

import GridSkeleton from 'src/components/common/GridSkeleton';
import Persons from './Persons';
import Socials from '../Socials';
import InstagramPopup from 'src/components/common/InstagramPopup';
import Footer from 'src/components/common/Footer';

import css from './MainPage.module.scss';

const { desktopBg, mobileBg, logoN12, logoN12Mini, whatsapp, intro } = images;

/**
 * a - rank ?
 * b - first name
 * c - last name
 * d - age (number)
 * e - sex ?
 * f - place
 * g - symbol ?
 * h - age(d) + sub-text
 * i - age[d] + sub-text + place[f]
 * j - image link
 * k - unit
 * l - description
 */

const MainPage = () => {
  const { isFetching, settings, isPersonModalVisible } = useAppState();
  const dispatch = useAppDispatch();
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const returnedHostagesRef = React.useRef<HTMLDivElement>(null);
  const returnedIn2025Ref = React.useRef<HTMLDivElement>(null);
  const returnedByTsahalRef = React.useRef<HTMLDivElement>(null);
  const [isMobile] = useResponsive('MOBILE');
  const { hostages, returnedHostages, earlierKidnepped, returnedIn2025, returnedByTsahal } = useFiltered();
  const [isAnimation, setIsAnimation] = React.useState<boolean>(false);
  const [isSticky, setIsSticky] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>('');
  const [isScrolling, setIsScrolling] = React.useState<boolean>(false);

  const scrollPosRef = React.useRef(0);
  const scrollToMobRef = React.useRef(0);

  React.useEffect(() => {
    setTimeout(() => {
      if (
        !_.isEmpty(hostages) &&
        !_.isEmpty(returnedHostages) &&
        location.hash.includes('#returned') &&
        returnedHostagesRef &&
        returnedHostagesRef.current
      ) {
        window.scrollTo({
          top: isMobile ? returnedHostagesRef.current.offsetTop - 72 : returnedHostagesRef.current.offsetTop - 102,
          behavior: 'smooth',
        });
        window.history.replaceState(null, '', '/');
      }
    }, 1000);
  }, [hostages, returnedHostages, isMobile]);

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isMobile]);

  const onScroll = () => {
    scrollPosRef.current = window.scrollY;

    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }

    if (window.pageYOffset > window.innerHeight / 2) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  const onPersonCandleBtnClick = (person: PersonInfo) => {
    scrollToMobRef.current = scrollPosRef.current;
    dispatch({ type: 'SET_SELECTED_PERSON', payload: person });
    dispatch({ type: 'SET_IS_PERSON_MODAL_VISIBLE', payload: true });
  };

  const onSearchBtnClick = () => {
    if (!searchInputRef.current?.value) {
      return;
    }

    setIsAnimation(true);

    setTimeout(() => {
      setIsAnimation(false);
    }, 1000);

    dispatch({ type: 'SET_FILTERS', payload: { search: searchInputRef.current?.value || '' } });

    analytics.gtag.event('search', { CUSTOM_PARAMETER: searchInputRef.current?.value });
  };

  const clickOnClear = () => {
    dispatch({ type: 'SET_FILTERS', payload: { search: '' } });

    if (searchInputRef.current) searchInputRef.current.value = '';
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchInputRef.current?.value) {
      onSearchBtnClick();
    }
  };

  const scrollTo = () => {
    returnedHostagesRef.current?.scrollIntoView({
      behavior: 'smooth',
    });

    analytics.gtag.event('hostages returned');
  };

  const scrollToReturnedByTsahal = () => {
    returnedByTsahalRef.current?.scrollIntoView({
      behavior: 'smooth',
    });

    analytics.gtag.event('idfbutton');
  };

  const scrollToReturnedIn2025 = () => {
    returnedIn2025Ref.current?.scrollIntoView({
      behavior: 'smooth',
    });

    analytics.gtag.event('2025button');
  };

  const onScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {
    if (isPersonModalVisible) {
      document.getElementsByTagName('body')[0].style.height = '100vh';
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.height = 'auto';
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
      if (isMobile) {
        window.scrollTo({
          top: scrollToMobRef.current,
          left: 0,
          behavior: 'auto',
        });
      }
    }
  }, [isPersonModalVisible]);

  React.useEffect(() => {
    // useEffect not working with ref change
    if (!search) {
      dispatch({ type: 'SET_FILTERS', payload: { search: '' } });
    }
  }, [search]);

  return (
    <div className={cn(css.totalWrapper)}>
      <div className={css.pageWrapper}>
        <div className={css.fixedBg} style={{ backgroundImage: `url(${isMobile ? mobileBg : desktopBg})` }} />
        <header className={cn(css.header, isSticky && css.sticky)}>
          <a
            href="http://www.mako.co.il/collab/makoapps.html?app=news"
            target="_blank"
            rel="noopener noreferrer"
            className={css.n12Link}
            onClick={() => analytics.gtag.event('logo', { CUSTOM_PARAMETER: 'N12' })}
          >
            <img src={isMobile ? logoN12Mini : logoN12} alt="n12 logo" />
          </a>
          <div className={css.title} onClick={clickOnClear}>
            {settings.mainTitle}
          </div>
          <Socials />
        </header>
        <div className={css.topSection}>
          <h1 className={css.title}>{settings.mainTitle}</h1>
          <p className={css.subTitle}>כל השמות, כל התמונות, כל הסיפורים</p>
          <p className={css.text}>{settings.subTitle}</p>
          <div className={css.buttons}>
            {_.size(returnedIn2025) !== 0 && (
              <div className={css.scrollToButton} onClick={scrollToReturnedIn2025}>{`הושבו בעסקה - 2025 >>`}</div>
            )}
            <div className={css.scrollToButton} onClick={scrollTo}>{`הושבו בעסקה - 2023 >>`}</div>
            {_.size(returnedByTsahal) !== 0 && (
              <div className={css.scrollToButton} onClick={scrollToReturnedByTsahal}>{`חולצו בידי הצבא >>`}</div>
            )}
          </div>
        </div>

        <div className={css.searchContainer}>
          <div className={cn(css.searchWrapper, searchInputRef.current?.value !== '' && css.isFocused)}>
            <div className={css.inputWrapper}>
              <form action="" onSubmit={onSubmit}>
                <input
                  ref={searchInputRef}
                  type="search"
                  placeholder={isMobile ? 'חיפוש' : 'חפשו שם'}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                />
              </form>
              {searchInputRef.current?.value !== '' && <div className={css.clearBtn} onClick={clickOnClear}></div>}
            </div>

            <button className={cn(css.searchBtn)} onClick={onSearchBtnClick}>
              <svg className={css.searchIcon} width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.65 0C2.989 0 0 2.989 0 6.65c0 3.661 2.989 6.65 6.65 6.65a6.61 6.61 0 0 0 4.345-1.633l.405.405V13.3l5.7 5.7 1.9-1.9-5.7-5.7h-1.228l-.405-.405A6.61 6.61 0 0 0 13.3 6.65C13.3 2.989 10.311 0 6.65 0Zm0 1.9a4.736 4.736 0 0 1 4.75 4.75 4.736 4.736 0 0 1-4.75 4.75A4.736 4.736 0 0 1 1.9 6.65 4.736 4.736 0 0 1 6.65 1.9Z"
                  fill="#0c2b4b"
                />
              </svg>
            </button>
          </div>
        </div>

        {_.size(hostages) === 0 && _.size(returnedHostages) === 0 && _.size(earlierKidnepped) === 0 && !isFetching && (
          <div className={css.noSearchResults}>לא נמצאו תוצאות</div>
        )}
        {(_.size(hostages) === 0 || _.size(returnedHostages) === 0) && isFetching && <GridSkeleton />}

        {_.size(hostages) !== 0 && (
          <div className={css.itemsGrid}>
            {_.map(hostages, (group) => {
              return (
                _.size(group) > 0 && (
                  <Persons
                    key={group[0].group}
                    showItems={group}
                    isAnimation={isAnimation}
                    onPersonCandleBtnClick={onPersonCandleBtnClick}
                  />
                )
              );
            })}
          </div>
        )}
        {_.size(returnedHostages) !== 0 && (
          <div
            className={cn(css.fullWidth, search && _.size(hostages) === 0 && css.isSearchResult)}
            ref={returnedHostagesRef}
          >
            <h3 className={css.gridTitle}>החטופים שהושבו בעסקה - 2023</h3>
            <div className={css.itemsGrid}>
              {_.map(returnedHostages, (group) => (
                <Persons
                  key={group[0].group}
                  showItems={group}
                  isAnimation={isAnimation}
                  onPersonCandleBtnClick={onPersonCandleBtnClick}
                />
              ))}
            </div>
          </div>
        )}
        {_.size(returnedIn2025) !== 0 && (
          <div
            className={cn(css.fullWidth, search && _.size(hostages) === 0 && css.isSearchResult)}
            ref={returnedIn2025Ref}
          >
            <h3 className={css.gridTitle}>{`החטופים שהושבו בעסקה - 2025`}</h3>
            <div className={css.itemsGrid}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {_.map(returnedIn2025, (group) => (
                <Persons
                  key={group[0].group}
                  showItems={group}
                  isAnimation={isAnimation}
                  onPersonCandleBtnClick={onPersonCandleBtnClick}
                />
              ))}
            </div>
          </div>
        )}
        {_.size(returnedByTsahal) !== 0 && (
          <div
            className={cn(css.fullWidth, search && _.size(hostages) === 0 && css.isSearchResult)}
            ref={returnedByTsahalRef}
          >
            <h3 className={css.gridTitle}>{`החטופים שחולצו בידי הצבא`}</h3>
            <div className={css.itemsGrid}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {_.map(returnedByTsahal, (group) => (
                <Persons
                  key={group[0].group}
                  showItems={group}
                  isAnimation={isAnimation}
                  onPersonCandleBtnClick={onPersonCandleBtnClick}
                />
              ))}
            </div>
          </div>
        )}

        {_.size(earlierKidnepped) !== 0 && (
          <div
            className={cn(
              css.fullWidth,
              css.isEarlierKidnepped,
              search && _.size(hostages) === 0 && _.size(returnedHostages) === 0 && css.isSearchResult,
            )}
          >
            <h3 className={css.gridTitle}>{EARLIER_KIDNEPPED.title}</h3>
            <div className={css.itemsGrid}>
              <Persons
                showItems={earlierKidnepped}
                isAnimation={isAnimation}
                onPersonCandleBtnClick={onPersonCandleBtnClick}
                isEarlierKidnepped
              />
            </div>
          </div>
        )}

        {/* <div className={css.contact}>
        <a href="https://wa.me/972502326004" target="_blank" rel="noopener noreferrer" className={css.whatsappLink}>
          <img src={whatsapp} alt="whatsapp icon" />
          <span>להוספת מידע על החטופים כתבו לנו בוואטסאפ האדום של mako &rsaquo;</span>
        </a>
      </div> */}

        <PersonModal />
        <InstagramPopup />
        <MakoAnalytics vcmId="HP" />
      </div>
      <Footer />
      <div className={css.credits}>
        Developed by{' '}
        <a href="https://www.rabbi.co.il/" target="_blank" rel="noopener noreferrer" className={css.rabbiLink}>
          Rabbi
        </a>
      </div>
      {isScrolling && (
        <div className={css.scrollTopButton} onClick={onScrollTop}>
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1901_9661)">
              <rect width="80" height="80" fill="#0C2B4B" />
              <path d="M24.7 52.7L40 37.4333L55.3 52.7L60 48L40 28L20 48L24.7 52.7Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_1901_9661">
                <rect width="80" height="80" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
};

export default MainPage;
